<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">

      <!-- CTA box -->
      <div class="bg-gray-800 py-10 px-8 md:py-16 md:px-12">
        <div class="flex flex-col lg:flex-row justify-between items-center">

          <!-- CTA content -->
          <div class="mb-6 lg:mr-16 lg:mb-0 text-center lg:text-left lg:w-3/4">
            <h3 class="h3 text-white mb-2">Just want to say hello?</h3>
            <p class="text-gray-400 text-lg">Drop us a line and we will get back to you shortly.</p>
          </div>

          <!-- CTA button -->
          <div class="w-full lg:w-1/4 flex justify-center lg:justify-end">
            <a class="btn text-white bg-purple-600 hover:bg-purple-700" href="mailto:hello@idntty.org">Contact us</a>
          </div>

        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'CtaContact'
}
</script>